body {
  background-color: rgb(92, 92, 92);
  outline: 0;
}
*:focus {
  outline: none;
}
table {
  table-layout: fixed;
}
iframe {
  border: 2px solid rgba(42, 42, 42, 0.5);
  padding: 2px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.about-us {
}
.about-us .about-us-img {
  padding: 50px;
}
.about-us .location-block h4 {
  margin-bottom: -20px;
}
.about-us .location-block p {
  margin-bottom: -20px;
}
.about-us .location-block a {
  color: inherit;
}
.announcement-modal {
  padding: 60px;
}
.announcement-modal .button {
  margin: 60px;
  margin-bottom: 0px;
  width: 50%;
}
.article {
  --articleMargin: 25px;
  margin-top: var(--articleMargin);
  margin-bottom: var(--articleMargin);
}
.bulletin {
  margin-top: 25px;
}
.bulletin .event-card {
  position: relative;
  margin: 15px;
}
.bulletin .event-card .content {
  display: inline-block;
  width: 55%;
}
.bulletin .event-card .image {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  margin: 50px;
  margin-top: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.bulletin .event-card .image img {
  background-image: linear-gradient(to bottom, transparent 0%, white 100%);
  width: 350px;
  border: 1px solid gray;
  padding: 0px;
  border-radius: 5px;
}
.calendar {
  margin-top: 25px;
}
.card-table {
  margin-top: 10px;
}
.card {
  cursor: pointer;
  margin: 25px;
}
.card .img-wrapper {
  text-align: center;
}
.card .img-wrapper img {
  width: 50%;
  border: 2px solid white;
  border-radius: 50%;
}
.card p {
  height: 50px;
}
.contact_us {
  margin-top: 25px;
}
.contact_us .required {
  color: red;
  display: inline-flex;
}
.contact_us .sub {
  font-size: 10px;
  margin-left: 18px;
}
.contact_us .form {
  border: 1px solid rgba(42, 42, 42, 0.5);
  border-radius: 3px;
  padding: 15px;
}
.contact_us .input input {
  padding: 5px;
  width: 75%;
  min-width: 350px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid rgba(42, 42, 42, 0.5);
}
.contact_us .input input:focus {
  outline: 0;
}
.contact_us .input textarea {
  padding: 5px;
  width: 75%;
  min-width: 350px;
  font-size: 14px;
  border-radius: 3px;
  border-color: rgba(42, 42, 42, 0.5);
}
.contact_us .input textarea:focus {
  outline: 0;
}
.custom_button {
  position: relative;
}
.custom_button button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  margin-right: 15px;
}
.custom_button .checkmark {
  position: absolute;
  top: -12.5px;
  left: -5px;
  pointer-events: none;
}
.custom_button button:focus {
  outline: 0;
}
.divider {
  --veritcalMargin: 10px;
  margin-top: var(--veritcalMargin);
  margin-bottom: var(--veritcalMargin);
}
.faq {
  margin-top: 25px;
}
.header {
  top: 0%;
  position: -webkit-sticky;
  position: sticky;
  z-index: 990;
  box-shadow: 5px 2px 7.5px 10px rgba(0, 0, 0, 0.25);
}
.header .wrapper {
  position: relative;
  height: 50px;
  display: inline-block;
  width: 35%;
}
.header .wrapper h3 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-family: "Kaushan Script";
  font-size: 35px;
}
.header img {
  width: 50px;
}
.home {
}
.home .announcement-container {
  position: relative;
  text-shadow: 0px 0px 3px black;
}
.home .announcement-container .banner {
  position: absolute;
  padding: 15px;
  top: 50%;
  right: 0;
  background-color: white;
  margin: 35px;
  border-radius: 10px;
  width: 250px;
}
.home button {
  border-radius: 5px;
}
.media-player {
}
.media-player img {
  width: 125px;
  border: 0px solid rgb(51, 51, 51);
  border-radius: 50%;
  float: left;
  margin-right: 25px;
}
.media-player h1 {
  font-size: 25px;
  margin-bottom: -0px;
}
.media-player h2 {
  font-size: 20px;
}
.media-player h3 {
  font-size: 12px;
  float: right;
}
.media-player audio {
  width: 25%;
  border: 2px solid rgba(42, 42, 42, 0.5);
  border-radius: 30px;
  padding: 2px;
}
.media-player .media-player-icon {
  position: relative;
}
.media-player .media-player-icon .ring {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: rotation 12s infinite linear;
          animation: rotation 12s infinite linear;
}
.media-player .media-player-icon .icon {
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.modal {
  z-index: 999;
  position: relative;
}
.modal .content {
  border: 1px solid rgba(42, 42, 42, 0.5);
  border-radius: 3px;
  padding: 15px;
  padding-top: 25px;
  margin-bottom: 25px;
}
.modal h1 {
  position: absolute;
  background-color: white;
  top: 20px;
  left: 100px;
}
.modal p {
  margin: 5px;
}
.modal .body {
  padding: 50px;
}
.modal h4 {
  margin-bottom: 50px;
}
.modal .close {
}
.my-calendar {
  margin-top: 25px;
}
.my-calendar .calendar {
  height: 750px;
}
.pdf-wrapper {
  position: relative;
  display: inline-block;
  text-decoration: none;
  text-align: left;
}
.pdf-wrapper h1 {
  font-size: 18px;
  margin-bottom: 0;
  margin-left: 15px;
}
.pdf-wrapper h2 {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 15px;
}
.pdf-wrapper .pdf {
  cursor: pointer;
  display: inline-block;
  border: 2px solid gray;
  border-radius: 3px;
  padding: 3px;
}
.pdf-wrapper .download {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.personel-card-table {
  text-align: center;
  margin-top: 0px;
}
.personel {
  position: relative;
  background: rgb(42, 42, 42);
  text-align: center;
  margin: 0px;
  display: inline-block;
  border: 0px solid white;
  border-spacing: 3px;
}
.personel .text-block {
  text-align: left;
  height: 325px;
}
.personel .text-block .sub-text {
  font-size: 14px;
  font-style: italic;
}
.personel .contact {
  text-align: left;
}
.personel .contact p {
  --veritcalMargin: -10px;
  margin-top: var(--verticalMargin);
  margin-bottom: var(--verticalMargin);
}
.personel-outline {
  top: 0%;
  left: 0%;
  position: absolute;
  border-radius: 0%;
  width: 100%;
  height: 100%;
  border: 3px solid rgba(0, 0, 0, 0);
  transition: all 0.5s;
}
.personel-outline:hover {
  border-color: rgb(135, 135, 135);
  -webkit-transform: translate(10px, 10px);
          transform: translate(10px, 10px);
}
.personel .img img {
  --width: 175px;
  min-width: var(--width);
  width: var(--width);
  border-radius: 50%;
  border: 3px solid rgb(135, 135, 135);
  padding: 2px;
}
.personel .img .alt {
  --width: 162.5px;
  min-width: var(--width);
  width: var(--width);
  min-height: var(--width);
  height: var(--width);
  border-radius: 50%;
  border: 3px solid rgb(135, 135, 135);
  padding: 2px;
}
.sermons {
  margin-top: 25px;
  position: relative;
}
.sermons .buffer {
  margin: 100px;
}
.sermons a {
  text-decoration: none;
}
.sermons .search-bar {
  width: 35%;
  min-width: 350px;
}
.sermons .inner-man-button {
  position: absolute;
  top: 125px;
  right: 0%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.sermons .inner-man-button-written {
  font-size: 24px;
  position: absolute;
  top: 85px;
  right: 0%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.title-text {
  font-size: 30px;
  font-weight: lighter;
}
.video {
  margin-top: 25px;
}
.video-player {
  text-align: center;
}
.video-player .text-block {
  margin-top: 35px;
  text-align: left;
}
.video-player .video-container {
  text-align: left;
  width: 50%;
}
.video-player .video-container video {
  border: 2px solid rgba(42, 42, 42, 0.5);
  padding: 2px;
  border-radius: 5px;
}

